<template>
  <div class="jingcai">
    <headbar />

    <div class="container">
      <div class="swiper">
        <img src="@/assets/img/base/swiper-left.png" class="swiper-icon q-mr-sm" @click="swiperChange(1)" />
        <img :src="guessList[active].img" class="cover" />
        <img src="@/assets/img/base/swiper-right.png" class="swiper-icon q-ml-sm" @click="swiperChange(2)" />
      </div>

      <div class="btn" @click="tolink('jingcaiDetails?modeType=' + guessList[active].value)">{{ $t('进入') }}</div>
    </div>

    <tab-bar active="2" />
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  components: {
  },
  setup() {
    return {
      active: ref(0),
      guessList: [
        {img: require("@/assets/img/base/jingcai1.png"), value: 1},
        {img: require("@/assets/img/base/jingcai2.png"), value: 2},
        {img: require("@/assets/img/base/jingcai3.png"), value: 3}
      ]
    }
  },
  methods: {
    swiperChange(e) {
      if(e == 1) {
        this.active = this.active == 0 ? this.guessList.length - 1 : this.active - 1
      }
      if(e == 2) {
        this.active = this.active == this.guessList.length - 1 ? 0 : this.active + 1
      }
    },
    tolink(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style scoped>
.jingcai {
  background-image: url(~@/assets/img/base/page-footer-bg.png);
  background-size: 100% 320px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.container {
  height: calc(100vh - 170px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cover {
  width: 80%;
}

.swiper {
  display: flex;
  align-items: center;
  justify-content: center
}

.swiper-icon {
  width: 12px;
}

.btn {
  margin-top: 70px;
  width: 270px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #D51B8C;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  border-radius: 99px;
}
</style>